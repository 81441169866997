<template>
  <v-dialog v-model="dialog" max-width="450px">
    <v-card>
      <v-card-title>
        <span>Não tem acesso a esse número?</span>
      </v-card-title>
      <v-card-text>
        <p class="text-subtitle-1">
          Se você não tem mais acesso ao número de telefone cadastrado, entre em
          contato com o suporte para redefinir sua senha.
        </p>
        <div class="d-flex justify-space-around">
          <!-- <v-btn
            color="primary"
            class="white--text"
            href="https://api.whatsapp.com/send?phone=5514981651879"
            target="_blank"
          >
            <v-icon left>mdi-whatsapp</v-icon>
            Suporte
          </v-btn> -->
          <v-btn
            color="primary"
            class="white--text"
            :href="`mailto:${email}`"
            target="_blank"
          >
            <v-icon left>mdi-email</v-icon>
            Contato
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { email } from "@/themeConfig.js";
export default {
  data: () => ({
    dialog: false,
    email,
  }),
  mounted() {
    this.$root.$on("lost-phone-access", () => {
      this.dialog = true;
    });
  },
};
</script>

<style></style>
